export const Nextdoor = ({ size }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height={size}
		width={size}
		fill='currentColor'
		viewBox='0 0 132 132'>
		<path
			d='M66,0C29.5,0,0,29.5,0,66s29.5,66,66,66s66-29.5,66-66S102.5,0,66,0z M103.9,94.4c0,0.8-0.6,1.4-1.4,1.4H89.4
	c-0.8,0-1.4-0.6-1.4-1.4V65.1c0-6.5-5-13.9-13.2-13.9c-8.7,0-13.2,7.4-13.2,13.9v29.2c0,0.8-0.6,1.4-1.4,1.4H46.9
	c-0.6,0-1.1-0.3-1.3-0.9c-0.1-0.2-0.1-0.4-0.1-0.5V66.1c0-0.9-0.6-1.6-1.4-1.8c-11.5-3.2-15.7-12-16-24.6c0-0.6,0.3-1.1,0.9-1.3
	c0.2-0.1,0.4-0.1,0.6-0.1h10.3v0.1H43c0.8,0,1.4,0.6,1.4,1.4c0.2,5.2,1.2,7.9,3.6,9.4c0.6,0.3,1.5,0.1,1.9-0.5
	c5.1-7.5,14.2-12.5,24.8-12.5c16.5,0,29.2,11.8,29.2,26.8V94.4z'
		/>
	</svg>
);
