import Footer from './components/Footer';
import Header from './components/Header';
import Nav from './components/Nav';
import ProTips from './components/ProTips';
import About from './components/about/About';
import Booking from './components/commerce/Booking';

const App = () => {
	return (
		<>
			<Nav />
			<Header />
			<ProTips />
			<About />
			<Booking />
			<Footer />
		</>
	);
};

export default App;
