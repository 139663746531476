export const LinkedIn = ({ size }) => (
	<svg
		viewBox='0 0 800 800'
		xmlns='http://www.w3.org/2000/svg'
		height={size}
		width={size}
		fill='currentColor'>
		<path
			d='M0,0v800h800V0H0z M248.8,664.6H130.1V307.5h118.7V664.6z M189.5,258.8h-0.8c-39.8,0-65.6-27.4-65.6-61.7
	c0-35,26.6-61.7,67.2-61.7c40.6,0,65.6,26.7,66.4,61.7C256.6,231.4,230.9,258.8,189.5,258.8z M676.9,664.6H558.2v-191
	c0-48-17.2-80.7-60.1-80.7c-32.8,0-52.3,22.1-60.9,43.4c-3.1,7.6-3.9,18.2-3.9,28.9v199.4H314.5c0,0,1.6-323.6,0-357.1h118.7v50.6
	c15.8-24.3,43.9-59,107-59c78.1,0,136.7,51,136.7,160.7V664.6z'
		/>
	</svg>
);
